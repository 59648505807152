<template>
  <div class="document-preview-container">
    <div class="document-preview">
      <div class="preview-tags">
        <v-container>
          <v-row v-for="t in tags" :key="t" justify="end" no-gutters>
            <v-chip class="ma-1" color="green" variant="flat">{{ t }}</v-chip>
          </v-row>
        </v-container>
      </div>
      <div>Page {{ props.currentPageNumber }} of {{ props.totalPages }}</div>
      <img
        :src="props.page.imagePresignedUrl"
        class="document-preview-img"
        :style="{ opacity: documentPreviewLoaded ? 'unset' : '0%', ...transformStyle }"
        @click="onPreviewClick"
        @load="
          () => {
            documentPreviewLoaded = true;
          }
        " />
      <MiniGreenCirclePreloader v-if="!documentPreviewLoaded" class="preview-loader" />
    </div>
    <div class="document-sidebar">
      <v-form ref="formRef" validate-on="blur eager" @submit.prevent>
        <div v-if="props.displayEditFields && documentFieldsMapping[props.page.documentType] !== undefined">
          <div
            v-for="field in documentFieldsMapping[props.page.documentType]"
            :key="field.label"
            class="sidebar-actions">
            {{ void (fieldValue = props.page[field.key]) }}
            {{ void (validation = field.validator ? field.validator(fieldValue) : true) }}
            {{ void (error = props.errors[field.key]) }}
            <span class="intake-action-label">
              <span>{{ field.label }}</span>
              <span v-if="field.required">*</span>: </span
            >&nbsp;
            <span class="intake-action-value">
              <component
                :is="field.component || 'v-text-field'"
                v-if="!field.cmp"
                type="text"
                :name="field.label"
                :model-value="field.format ? field.format(fieldValue) : fieldValue"
                :class="{ 'invalid-value': error !== undefined }"
                :rules="field.rules"
                validate-on="eager invalid-input"
                @update:model-value="(e) => onAttributeChange(e, field)"
                @keyup="(e) => onAttributeChange(e, field)">
              </component>
              <!-- A generated dynamic component -->
              <component
                :is="field.cmp.component"
                v-if="field.cmp && field.cmp.component === 'v-autocomplete'"
                ref="autocompleteRefs"
                v-model="field.cmp.value"
                v-bind="field.cmp.vBind"
                validate-on="eager blur"
                :class="{ 'invalid-value': error !== undefined }"
                @update:model-value="(v) => onAttributeChange(v, field)"
                v-on="field.cmp.vOn"></component>
            </span>
            <div v-if="validation !== true" class="action-confidence">
              {{ validation }}
            </div>
          </div>
        </div>
        <slot />
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { IntakeDocumentPage } from "@/models/intake";
import { onMounted, ref, watch } from "vue";
import { insuranceCardFields, orderFormFields, resetFields } from "@/utils/documentProcessing/pageFields";
import MiniGreenCirclePreloader from "../MiniGreenCirclePreloader.vue";

const autocompleteRefs = ref(null);
const formRef = ref(null);

const emit = defineEmits(["attributeEdit"]);
const props = defineProps({
  page: {
    required: true,
    type: IntakeDocumentPage,
  },
  displayEditFields: {
    required: false,
    type: Boolean,
    default: true,
  },
  tags: {
    required: false,
    type: Array,
    default: () => [],
  },
  errors: {
    required: false,
    type: Object,
    default: () => ({}),
  },
  currentPageNumber: {
    required: true,
    type: Number,
  },
  totalPages: {
    required: true,
    type: Number,
  },
});

defineExpose({
  formRef,
  autocompleteRefs,
});

const documentPreviewLoaded = ref(false);
const documentFieldsMapping = ref({
  ORDER_FORM: orderFormFields,
  INSURANCE_CARD: insuranceCardFields,
});
const transformStyle = ref({});

onMounted(() => {
  resetFields({
    diagnosisIcd10Code: props.page.diagnosisIcd10Code,
  });
});

// hack to get autocomplete to validate on item select
watch(
  () => autocompleteRefs.value?.[0]?.items,
  () => {
    // validate everytime the selection list changes
    autocompleteRefs.value[0]?.validate();
  }
);

watch(
  () => props.page.imagePresignedUrl,
  () => {
    documentPreviewLoaded.value = false;
  }
);

watch(
  () => props.page,
  () => {
    resetFields({
      diagnosisIcd10Code: props.page.diagnosisIcd10Code,
    });
  }
);

const onPreviewClick = (ev) => {
  if (transformStyle.value.transform) {
    transformStyle.value = {};
    return;
  }
  const bounds = ev.target.getBoundingClientRect();
  const hRatio = (ev.clientX - bounds.left) / (bounds.right - bounds.left);
  const vRatio = (ev.clientY - bounds.top) / (bounds.bottom - bounds.top);
  transformStyle.value = {
    transform: "scale(150%)",
    "transform-origin": `${hRatio * 100}% ${vRatio * 100}%`,
  };
};

const onAttributeChange = (e, field) => {
  emit("attributeEdit", field, e?.currentTarget ? e.currentTarget.value : e);
};
</script>

<!-- TODO: split css files -->
<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
