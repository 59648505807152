import { defineStore } from "pinia";

export const useIntakeStore = defineStore("intake-store", () => {
  const intakeModel = ref({
    id: null,
    ocr_task: {
      id: null,
      status: null,
      status_changed_at: null,
    },
    patient: {
      id: null,
      created_on: null,
      modified_on: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      dob: null,
    },
    created_on: null,
    modified_on: null,
    status: null,
    status_changed_at: null,
    key: null,
    // format: null,
    // input_s3_key: null,
    input_presigned_url: null,
    // has_order_page: null,
  });
  return { intakeModel };
});

export const useIntakePageStore = defineStore("intake-page-store", () => {
  const imagePresignedUrl = ref(null); // string

  const documentType = ref(null); // string
  const pageNumber = ref(0);

  const patientFirstName = ref(null); // string
  const patientLastName = ref(null); // string
  const patientDob = ref(null); // Date
  const patientPhone = ref(null); // string

  const patientWeight = ref(null); // string
  const patientStatus = ref(null); // string
  const diagnosisIcd10Code = ref(null); // string
  const providerFullName = ref(null); // string
  const providerNpi = ref(null); // string
  const providerFullAddress = ref(null); // string
  const providerPracticeName = ref(null); // string
  const providerPhone = ref(null); // string
  const providerFax = ref(null); // string
  const preMedication = ref({});
  const therapyAdministration = ref({});
  const otherCheckboxes = ref([]);
  const specialInstructions = ref([]);

  const insurancePlan = ref(null); // string
  const phone1Number = ref(null); // string
  const phone1Description = ref(null); // string
  const phone2Number = ref(null); // string
  const phone2Description = ref(null); // string
  const fax1Number = ref(null); // string
  const fax1Description = ref(null); // string
  const memberName = ref(null); // string
  const memberId = ref(null); // string
  const memberDob = ref(null); // Date
  const planType = ref(null); // string
  const state = ref(null); // string
  const groupNumber = ref(null); // string
  const startDate = ref(null); // Date
  const endDate = ref(null); // Date
  const rxBin = ref(null); // string
  const rxPcn = ref(null); // string
  const rxGrp = ref(null); // string

  const $reset = () => {
    imagePresignedUrl.value = null;
    documentType.value = null;
    pageNumber.value = 0;
    patientFirstName.value = null;
    patientLastName.value = null;
    patientDob.value = null;
    patientPhone.value = null;
    patientWeight.value = null;
    patientStatus.value = null;
    diagnosisIcd10Code.value = null;
    providerFullName.value = null;
    providerNpi.value = null;
    providerFullAddress.value = null;
    providerPracticeName.value = null;
    providerPhone.value = null;
    providerFax.value = null;
    preMedication.value = {};
    therapyAdministration.value = {};
    otherCheckboxes.value = [];
    specialInstructions.value = [];
    insurancePlan.value = null;
    phone1Number.value = null;
    phone1Description.value = null;
    phone2Number.value = null;
    phone2Description.value = null;
    fax1Number.value = null;
    fax1Description.value = null;
    memberName.value = null;
    memberId.value = null;
    memberDob.value = null;
    planType.value = null;
    state.value = null;
    groupNumber.value = null;
    startDate.value = null;
    endDate.value = null;
    rxBin.value = null;
    rxPcn.value = null;
    rxGrp.value = null;
  };

  return {
    $reset,
    imagePresignedUrl,
    documentType,
    pageNumber,
    patientFirstName,
    patientLastName,
    patientDob,
    patientPhone,
    patientWeight,
    patientStatus,
    diagnosisIcd10Code,
    providerFullName,
    providerNpi,
    providerFullAddress,
    providerPracticeName,
    providerPhone,
    providerFax,
    preMedication,
    therapyAdministration,
    otherCheckboxes,
    specialInstructions,
    insurancePlan,
    phone1Number,
    phone1Description,
    phone2Number,
    phone2Description,
    fax1Number,
    fax1Description,
    memberName,
    memberId,
    memberDob,
    planType,
    state,
    groupNumber,
    startDate,
    endDate,
    rxBin,
    rxPcn,
    rxGrp,
  };
});
