<template>
  <div class="document-footer">
    <div class="paging-buttons">
      <v-form @submit.prevent>
        <v-btn class="prev-page" @click="onClickPrevPage">&lt; Prev Page</v-btn>
        <v-btn class="next-page" @click="onClickNextPage">Next Page &gt;</v-btn>
      </v-form>
    </div>
    <slot />
    <div v-if="props.intakeStatus === 'Qualify Patient'" class="qualify-footer">
      <v-form @submit.prevent="noSubmit">
        <v-btn class="go-back" @click="onBack">Go Back</v-btn>
        <v-btn class="submit" @click="onclickQualifySubmit">Looks Good</v-btn>
      </v-form>
    </div>
    <div v-if="props.intakeStatus === 'Clinical Review'" class="clinical-footer">
      <v-form @submit.prevent="noSubmit">
        <!-- <div class="assign-document-container">
              <span class="assign-label">Assign Document As:</span>
              <select name="" class="custom-select-arrow">
                <option value="0">Insurance Card</option>
                <option value="1">Order Form</option>
                <option value="3">Patient Records</option>
              </select>
              <v-btn>Assign</v-btn>
            </div> -->
      </v-form>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["submit-step", "back-step", "next-page", "prev-page"]);
const props = defineProps({
  intakeStatus: { type: String, required: true },
  currentPage: { type: Number, required: true },
  totalPages: { type: Number, required: true },
  finishReviewEnabled: { type: Boolean, required: true },
});

const onclickQualifySubmit = () => {
  emit("submit-step");
};

const onClickNextPage = () => {
  emit("next-page");
};

const onClickPrevPage = () => {
  emit("prev-page");
};

const onBack = () => {
  emit("back-step");
};
</script>

<!-- TODO: split css files -->
<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
