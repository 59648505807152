<template>
  <div>
    <div v-if="!selectedTerm" class="input-elements-group" :class="{ expanded: isSearchDropdownVisible }">
      <label :for="`search-term-${props.data.searchId}`">
        {{ props.data.label + (props.required ? "*" : "") }}
      </label>
      <input
        v-if="props.allowFreeTextInput"
        :id="`search-term-${props.data.searchId}`"
        v-model="typedText"
        type="text"
        :placeholder="`${props.data.label}`"
        :disabled="props.disabled"
        @keyup="getFreeTextInput"
        @keyup.esc="cleanInputAndHideDropdown" />
      <input
        v-else
        :id="`search-term-${props.data.searchId}`"
        v-model="typedText"
        type="text"
        :placeholder="`${props.data.label}`"
        :disabled="props.disabled"
        @keyup.esc="cleanInputAndHideDropdown" />
    </div>
  </div>
  <div v-if="isSearchDropdownVisible && suggestions.length" class="search-dropdown-block" style="margin-right: 20px">
    <ul>
      <li v-for="item in suggestions" :key="item.label" @click="selectItem(item)">
        <span name="list-item-text">
          {{ item.label }}
          <span v-if="item.additionalInfo">[{{ item.additionalInfo }}]</span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { watch, ref, shallowRef } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  required: {
    type: Boolean,
    required: false,
  },
  allowFreeTextInput: {
    type: Boolean,
    default() {
      return false;
    },
  },
  disabled: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const emit = defineEmits(["item-selected"]);

const isSearchDropdownVisible = ref(false);
const suggestions = shallowRef([]);
const typedText = ref("");
const selectedTerm = ref(false);

watch(typedText, (newValue) => {
  if (newValue.length > 0) {
    isSearchDropdownVisible.value = true;
    suggestions.value = props.data.options.filter((option) => option.searchText.includes(newValue.toLowerCase()));
  } else {
    isSearchDropdownVisible.value = false;
  }
});

function selectItem(item) {
  emit("item-selected", item);
  isSearchDropdownVisible.value = false;
  typedText.value = "";
  selectedTerm.value = true;
}

function getFreeTextInput(event) {
  if ((event.code === "Enter" || event.code === 76) && typedText.value.length) {
    const freeTextInputItem = {
      label: typedText.value,
    };
    selectItem(freeTextInputItem);
  }
}

function cleanInputAndHideDropdown() {
  setTimeout(() => {
    isSearchDropdownVisible.value = false;
    typedText.value = "";
  }, 100);
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
@import "../styles/pages/_insurance-navigator-page";
</style>
