<template>
  <PriorHeader />
  <GreenCirclePreloader v-if="showLoader" />
  <v-alert v-if="errorMsg" :text="errorMsg" type="error" variant="outlined"></v-alert>
  <v-alert
    v-if="!showLoader && insuranceCardPages !== undefined && insuranceCardPages.length === 0"
    text="This patient has no insurance cards available"
    type="error"
    variant="outlined"></v-alert>
  <div v-if="!showLoader && currentDocumentPage" class="patient-intake-container">
    <h1 class="main-header">Qualify Patient</h1>
    <div class="review-container">
      <!-- <ReviewHeader :intake-status="intakeStatus" :page="currentDocumentPage" @type-change="onDocumentTypeChange" />
      <hr class="bg-blue" /> -->
      <PatientBar
        v-if="patientInfo"
        :patient="patientInfo"
        :disabled="true"
        @change-attribute="onPatientAttributeChange" />
      <PagePreview
        v-if="currentDocumentPage"
        :page="currentDocumentPage"
        :stage="intakeStatus"
        :total-pages="insuranceCardPages.length"
        :current-page-number="pageChanger.currentPageIndex + 1"
        @attribute-edit="onAttributeEdit" />
      <div class="document-footer">
        <v-btn :class="{ secondary: activeInsuranceCardId === currentDocumentPage?.id }" @click="markAsActiveInsurance">
          {{ activeInsuranceCardId === currentDocumentPage?.id ? "Active Insurance" : "Mark as Active Insurance" }}
        </v-btn>
        <BottomBar
          :current-page="pageChanger.currentPageIndex"
          :finish-review-enabled="finishReviewEnabled"
          :intake-status="intakeStatus"
          :total-pages="totalPages"
          @submit-step="onLooksGood"
          @back-step="goBack"
          @prev-page="() => pageChanger.onPrevPage()"
          @next-page="() => pageChanger.onNextPage()" />
      </div>
    </div>
  </div>
  <div v-if="looksGood" class="qualify-form">
    <form @submit.prevent="">
      <div class="header">
        Qualify Patient
        <span v-if="intakeOrder?.insuranceCardPage" :style="{ color: payorInNetwork ? 'green' : 'red' }">
          {{ payorInNetwork ? "Payor in network" : "Payor not in network" }}
        </span>
      </div>
      <hr />
      <br />
      <v-combobox
        v-model="continueProcessing"
        :items="['Yes', 'No']"
        label="Do you want to continue processing this patient?" />
      <br />
      <v-combobox
        v-if="continueProcessing === 'No'"
        v-model="notifyProvider"
        :items="['Yes', 'No']"
        label="Do you want to notify the referring provider if you will not be processing this patient?" />
      <div v-if="notifyProvider === 'Yes' && continueProcessing === 'No'">
        <label for="qualify-patient-template"> If yes, please update notification template: </label>
        <v-textarea
          id="qualify-patient-template"
          v-model="notifyProviderTemplate"
          name="qualify-patient-template"
          class="custom-select-arrow"></v-textarea>
      </div>
    </form>
  </div>
  <div v-if="looksGood">
    <v-container>
      <v-row no-gutters>
        <v-col>
          <v-btn @click="goBack">Go Back</v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="continueProcessing === 'Yes'" class="float-right" @click="submitStep">Submit</v-btn>
          <v-btn v-if="continueProcessing === 'No'" class="float-right" @click="archiveOrder">Archive Order</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <PriorFooter />
</template>

<script setup>
// Core imports
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useDate } from "vuetify";

// Component imports
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import PriorFooter from "@/components/PriorFooter.vue";
import PriorHeader from "@/components/PriorHeader.vue";
import BottomBar from "@/components/intakeReview/BottomBar.vue";
import PagePreview from "@/components/intakeReview/PagePreview.vue";
import PatientBar from "@/components/intakeReview/PatientBar.vue";
// import ReviewHeader from "@/components/intakeReview/ReviewHeader.vue";

// Service and model imports
import { DOCUMENT_CLASSIFICATIONS, IntakeDocumentPage, PageChangeManager, Patient } from "@/models/intake";
import { intakeService } from "@/services/intakeService";
import router from "@/router";

const saveDocumentChanges = async (thisPageRef, dirtyValues) => {
  showLoader.value = true;
  errorMsg.value = "";
  try {
    await intakeService.patchIntakeDocumentPage(thisPageRef.value.documentKey, thisPageRef.value.id, dirtyValues);
  } catch (ex) {
    errorMsg.value = "Failed to save document changes:";
    return false;
  } finally {
    showLoader.value = false;
  }
  return true;
};

const dateAdapter = useDate();
const showLoader = ref(true);
const intakeOrder = ref(undefined);
const insuranceCardPages = ref([]);
const currentDocumentPage = ref(undefined);
const activeInsuranceCardId = ref(undefined);
const intakeStatus = ref("Qualify Patient");
const pageChanger = new PageChangeManager([], currentDocumentPage, { autoSaveCallback: saveDocumentChanges });
const errorMsg = ref(undefined);
const patientInfo = ref(undefined);
const payorInNetwork = ref(false);
const looksGood = ref(false);
const continueProcessing = ref("Yes");
const notifyProvider = ref("No");
const notifyProviderTemplate = ref("");

const route = useRoute();
onMounted(() => {
  fetchIntakeOrder();
});

const markAsActiveInsurance = async () => {
  errorMsg.value = "";
  try {
    intakeOrder.value = await intakeService.patchIntakeOrder(intakeOrder.value.id, {
      insuranceCardPageId: currentDocumentPage.value.id,
    });
    activeInsuranceCardId.value = intakeOrder.value.insuranceCardPage?.id;
    updatePayorInNetwork();
  } catch (e) {
    errorMsg.value = "Failed to mark insurance card as active";
  }
};

const updatePayorInNetwork = () => {
  payorInNetwork.value = (intakeOrder.value.payorNames ?? []).indexOf(currentDocumentPage.value?.payorName) !== -1;
};

const onAttributeEdit = (field, value) => {
  pageChanger.updateCurrentPageAttribute(field.key, value);
  if (field.key === "payorName") {
    updatePayorInNetwork();
  }
};

const onLooksGood = () => {
  errorMsg.value = "";
  if (!intakeOrder.value.insuranceCardPage) {
    errorMsg.value = "No active insurance card selected";
    return;
  }
  looksGood.value = true;
  updatePayorInNetwork();
};

const submitStep = async () => {
  showLoader.value = true;
  errorMsg.value = "";
  if (!intakeOrder.value.insuranceCardPage) {
    errorMsg.value = "No active insurance card selected";
    showLoader.value = false;
    return;
  }
  try {
    // Submit the order to clinical review
    await intakeService.postOrderSubmitQualify(intakeOrder.value.id);
    // We go back so the "processing" card shows up
    goBack();
  } catch (e) {
    errorMsg.value = "Failed to submit the Draft Order";
  }
  showLoader.value = false;
};

const archiveOrder = async () => {
  showLoader.value = true;
  errorMsg.value = "";
  try {
    await intakeService.postDiscontinueProcessing(
      intakeOrder.value.id,
      notifyProvider.value === "Yes",
      notifyProviderTemplate.value
    );
    router.push({ name: "all-patients" });
  } catch (e) {
    errorMsg.value = "Failed to archive the order or notify the provider";
  }
  showLoader.value = false;
};

const fetchPatientQualifyDocuments = async (patientId) => {
  errorMsg.value = "";
  try {
    const result = await intakeService.getPatientDocumentPages(
      patientId,
      DOCUMENT_CLASSIFICATIONS.INSURANCE_CARD.toUpperCase()
    );
    insuranceCardPages.value = result.results.map((r) => new IntakeDocumentPage(r));
    currentDocumentPage.value = insuranceCardPages.value[0];
    pageChanger.pages = insuranceCardPages.value;
  } catch (e) {
    errorMsg.value = "Failed to fetch patient qualify Documents";
  }
};

const fetchIntakeOrder = async () => {
  errorMsg.value = "";
  try {
    const result = await intakeService.getIntakeOrder(route.params.id);
    intakeOrder.value = result;
    patientInfo.value = new Patient(intakeOrder.value.patient, dateAdapter);
    const patientId = intakeOrder.value.patient.id;
    activeInsuranceCardId.value = intakeOrder.value.insuranceCardPage?.id;
    await fetchPatientQualifyDocuments(patientId);
  } catch (e) {
    errorMsg.value = "Error fetching the Draft Order";
  } finally {
    showLoader.value = false;
  }
};

const goBack = () => {
  router.push({ name: "all-patients" });
};
</script>

<!-- TODO: split css files -->
<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
