<template>
  <div :class="{ card: true, 'not-ready': props.disabled }" @click="onCardClick">
    <div class="title">{{ props.title }}</div>
    <div :class="{ subtitle: true, ready: !props.disabled }">{{ props.subtitle }}</div>
    <div class="subtitle">{{ props.desc }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: { required: true, type: String },
  disabled: { required: true, type: Boolean },
  subtitle: { required: true, type: String },
  desc: { required: true, type: String },
  onClick: { required: true, type: Function },
});
const onCardClick = () => (props.disabled ? false : props.onClick(props));
</script>

<style lang="scss" scoped>
@import "@/styles/components/_kanban-card.scss";
</style>
