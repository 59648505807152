<template>
  <div
    v-if="['Intake', 'Qualify Patient'].find((value) => value === props.intakeStatus)"
    class="document-classification">
    <v-form>
      <span style="font-size: 24px; font-weight: 700">Document Classification:</span>&nbsp;
      <span>
        <v-select
          v-if="props.page"
          class="custom-select-arrow"
          name="documentClassificationSelect"
          :model-value="props.page.documentType"
          :items="documentLabels"
          bg-color="white"
          placeholder="Select Classification"
          @update:model-value="onDocumentTypeChange">
        </v-select>
      </span>
      <!-- <span>
        <input type="text" placeholder="Descriptor" />
      </span> -->
    </v-form>
  </div>
</template>

<script setup>
import { DOCUMENT_CLASSIFICATION_LABELS, IntakeDocumentPage } from "@/models/intake";
import { ref } from "vue";

const emit = defineEmits("type-change");
const props = defineProps({
  intakeStatus: { type: String, required: true },
  page: { type: IntakeDocumentPage, required: true },
});
const documentLabels = ref(Object.entries(DOCUMENT_CLASSIFICATION_LABELS).map(([value, title]) => ({ value, title })));

const onDocumentTypeChange = (value) => {
  emit("type-change", value);
};
</script>

<!-- TODO: split css files -->
<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
