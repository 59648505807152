<template>
  <PriorHeader />
  <GreenCirclePreloader v-if="showLoader" />
  <v-alert v-if="errorMsg" :text="errorMsg" type="error" variant="outlined"></v-alert>
  <div v-if="!showLoader" class="patient-intake-container">
    <h1 class="main-header">New Patient Intake Review</h1>
    <div class="review-container">
      <ReviewHeader :intake-status="intakeStatus" :page="currentDocument" @type-change="onDocumentTypeChange" />
      <!-- <hr class="bg-blue" /> -->
      <!-- Adding a :key forces rerendering -->
      <PatientBar
        :key="`${patientInfo?.id}.${patientInfo?.modifiedOn}`"
        :patient="patientInfo"
        :patient-guess="documentInfo.patientGuess"
        @change-attribute="onPatientAttributeChange"
        @set-patient="onSetPatient"
        @save-new-patient="onSaveNewPatient" />
      <PagePreview
        v-if="currentDocument"
        ref="previewRef"
        :page="currentDocument"
        :stage="intakeStatus"
        :errors="pageErrors"
        :current-page-number="pageManager.currentPageIndex + 1"
        :total-pages="allPages.length"
        @attribute-edit="(field, value) => pageManager.updateCurrentPageAttribute(field.key, value)" />
      <div class="document-footer">
        <BottomBar
          :current-page="currentPage"
          :finish-review-enabled="finishReviewEnabled"
          :intake-status="intakeStatus"
          :total-pages="totalPages"
          @submit-step="submitStep"
          @back-step="backStep"
          @prev-page="prevPage"
          @next-page="nextPage">
          <div v-if="intakeStatus === 'Intake'" class="intake-footer">
            <v-form @submit.prevent="noSubmit">
              <v-btn @click="backStep">Go Back</v-btn>
              <v-btn class="submit" @click="submitStep">{{
                hasOrderPage ? "Continue to Qualify" : "Approve Document Review"
              }}</v-btn>
            </v-form>
          </div>
        </BottomBar>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import PriorHeader from "@/components/PriorHeader.vue";
import PriorFooter from "@/components/PriorFooter.vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { intakeService } from "@/services/intakeService";
import PagePreview from "@/components/intakeReview/PagePreview.vue";
import PatientBar from "@/components/intakeReview/PatientBar.vue";
import BottomBar from "@/components/intakeReview/BottomBar.vue";
import ReviewHeader from "@/components/intakeReview/ReviewHeader.vue";
import { DOCUMENT_CLASSIFICATIONS, IntakeDocumentPage, Patient, PageChangeManager } from "@/models/intake";
import router from "@/router";
import { useFieldPageErrors } from "@/utils/documentProcessing/pageFields";
import { patientService } from "@/services/patientService";
import debounce from "lodash.debounce";
import { useDate } from "vuetify";

const pageSaveCallback = async (currentPageRef, dirtyValues) => {
  // if (!validateFields(pageManager.currentPage)) {
  //   errorMsg.value = "There are issues  with the current page";
  //   return false;
  // }
  showLoader.value = true;
  errorMsg.value = "";
  try {
    await intakeService.patchIntakeDocumentPage(route.params.id, currentPageRef.value.id, dirtyValues);
  } catch (ex) {
    errorMsg.value = ex.message;
    return false;
  } finally {
    showLoader.value = false;
  }
  return true;
};

const route = useRoute();
const dateAdapter = useDate();

const documentInfo = ref(undefined);
const patientInfo = ref(undefined);
const allPages = ref([]);
const reviewContainerHeight = ref("70vh");
const currentPage = ref(null);
const currentDocument = ref(null);
const finishReviewEnabled = ref(false);
const intakeStatus = ref(null);
const pageManager = new PageChangeManager([], currentDocument, { autoSaveCallback: pageSaveCallback });
const { pageErrors, validateFields, nextPageWithErrors } = useFieldPageErrors();
const errorMsg = ref(undefined);
const documentKey = route.params.id;
const hasOrderPage = ref(false);
const previewRef = ref(null);

const showLoader = ref(true);

watch(
  [documentInfo],
  ([newInfo]) => {
    showLoader.value = !newInfo;
  },
  { immediate: true }
);

watch(
  () => pageManager.currentPage,
  (newPage) => {
    if (newPage) {
      validateFields(newPage);
    }
  }
);

onMounted(() => {
  fetchIntakeDocument();
  fetchIntakeDocumentPages();
});

const onDocumentTypeChange = (value) => {
  pageManager.updateCurrentPageAttribute("documentType", value);
  hasOrderPage.value = allPages.value.some((page) => page.documentType === DOCUMENT_CLASSIFICATIONS.ORDER_FORM);
};

const onPatientAttributeChange = (name, value) => {
  patientInfo.value[name] = value;
  // Async and debounced method
  savePatientEdits();
};

const savePatientEdits = debounce(async () => {
  await patientService.patchPatient(patientInfo.value.id, {
    firstName: patientInfo.value.firstName,
    lastName: patientInfo.value.lastName,
    phoneNumber: patientInfo.value.phoneNumber,
    dob: patientInfo.value.dob,
  });
}, 750);

const onSetPatient = async (patient) => {
  if (!patient) return;
  try {
    errorMsg.value = "";
    const response = await intakeService.patchIntakeDocument(documentKey, { patientId: patient.id });
    patientInfo.value = new Patient(response.patient, dateAdapter);
  } catch (e) {
    errorMsg.value = "Error saving patient to document";
  }
};

const onSaveNewPatient = async (newPatient) => {
  try {
    const savedPatient = await patientService.postPatient(newPatient);
    patientInfo.value = new Patient(savedPatient, dateAdapter);
    await onSetPatient(savedPatient);
  } catch (e) {
    errorMsg.value = "Error saving the new patient";
    patientInfo.value = undefined;
  }
};

const submitStep = () => {
  if (intakeStatus.value === "Intake") {
    onclickIntakeSubmit();
  }
};

const backStep = async () => {
  await pageManager.saveCurrentPage();
  router.push({ name: "all-patients" });
};

const prevPage = () => {
  errorMsg.value = "";
  pageManager.onPrevPage();
};

const nextPage = () => {
  errorMsg.value = "";
  pageManager.onNextPage();
};

const onclickIntakeSubmit = async () => {
  try {
    errorMsg.value = "";
    if (!(await pageManager.saveCurrentPage())) {
      return;
    }
    if (patientInfo.value === undefined) {
      errorMsg.value = "Please select a patient before continuing";
      return;
    }
    const currentPageErrors = pageErrors.value;
    const [pageIndex, pageWithErrors] = nextPageWithErrors(allPages.value);
    if (pageWithErrors) {
      errorMsg.value =
        `There are invalid fields present on page ${parseInt(pageIndex) + 1}: ` +
        `${Object.values(pageErrors.value)
          .map((error) => error.field.label)
          .join(", ")}`;
      pageErrors.value = currentPageErrors;
      return;
    }
    const order_page = allPages.value.find((p) => p.documentType === DOCUMENT_CLASSIFICATIONS.ORDER_FORM);
    const order = await intakeService.postIntakeDocumentSubmit(route.params.id, { order_page_id: order_page?.id });
    if (order) {
      // TODO: Add messaging to the all-patients page?
      router.push({ name: "all-patients" });
    } else {
      router.push({ name: "all-patients" });
    }
  } catch (ex) {
    errorMsg.value = `Error submitting the document: ${ex.message}`;
  } finally {
    showLoader.value = false;
  }
};

const fetchIntakeDocumentPages = async () => {
  try {
    const documentKey = route.params.id;
    const response = await intakeService.getIntakeDocumentPages(documentKey);
    const pages = [];
    response.results.forEach((pageData) => {
      const page = new IntakeDocumentPage(pageData);
      pages.push(page);
    });
    allPages.value = pages;
    pageManager.pages = pages;
    currentPage.value = 0;
    currentDocument.value = pages[0];
    reviewContainerHeight.value = "unset";
    hasOrderPage.value = allPages.value.some((page) => page.documentType === DOCUMENT_CLASSIFICATIONS.ORDER_FORM);
  } catch (e) {
    errorMsg.value = "Error fetching the document pages";
  }
};

const fetchIntakeDocument = async () => {
  try {
    const documentKey = route.params.id;
    const response = await intakeService.getIntakeDocument(documentKey);
    documentInfo.value = response;
    const patient = documentInfo.value.patient;
    if (patient) {
      patientInfo.value = new Patient(patient, dateAdapter);
    }
    intakeStatus.value = "Intake";
  } catch (e) {
    errorMsg.value = "Error fetching the document";
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
