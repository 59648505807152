// import Vue from 'vue'
/* eslint-disable */
import { createWebHistory, createRouter } from 'vue-router';
import { trackRouter } from "vue-gtag-next";
import { useAuthStore } from "@/stores";

// import HomePage from '@/pages/HomePage';
import InsuranceNavigatorPage from '@/pages/InsuranceNavigatorPage';
import RequirementsPage from '@/pages/RequirementsPage';
import SignUp from '@/pages/SignUp';
import CompleteRegistration from '@/pages/CompleteRegistration';
import SignIn from '@/pages/SignIn';
import PasswordResetRequest from '@/pages/PasswordResetRequest';
import PasswordReset from '@/pages/PasswordReset';
import ConfirmEmail from '@/pages/ConfirmEmail';
import SubmissionsListPage from '@/pages/SubmissionsListPage';
import SubmissionDetailsPage from '@/pages/SubmissionDetailsPage';
import PdfSubmissionDetailsPage from '@/pages/PdfSubmissionDetailsPage';
import UploadPriorAuthFormPage from '@/pages/UploadPriorAuthFormPage';
import ReviewInitiatedPriorAuthPage from '@/pages/ReviewInitiatedPriorAuthPage';
import BenefitInvestigationPage from '@/pages/BenefitInvestigationPage';
import HealthCheckView from '@/pages/HealthCheckView.vue';
import PatientsListPage from '@/pages/PatientsListPage.vue';
import PatientIntakeReview from './pages/PatientIntakeReview.vue';
import PatientIntakeQualifyPatient from './pages/PatientIntakeQualifyPatient.vue';
import PatientIntakeClinicalReview from './pages/PatientIntakeClinicalReview.vue';
import OrderDenialFollowUp from './pages/OrderDenialFollowUp.vue';

const publicAccessRoutes = [
  {
    path: "/register",
    name: "register",
    component: SignUp,
    title: "Do Prior Auth",
  },
  {
    path: "/complete-registration/:user_id?/:token?",
    name: "complete-registration",
    component: CompleteRegistration,
    title: "Do Prior Auth",
  },
  {
    path: "/login",
    name: "login",
    component: SignIn,
    title: "Do Prior Auth",
  },
  {
    path: "/password-reset-request",
    name: "password-reset-request",
    component: PasswordResetRequest,
    title: "Password Reset",
  },
  {
    path: "/confirm-email/:user_id/:token",
    name: "confirmEmail",
    component: ConfirmEmail,
    title: "Do Prior Auth",
  },
  {
    path: "/password-reset/:token?",
    name: "password-reset",
    component: PasswordReset,
    title: "Password Reset",
  },
  {
    path: "/health-check",
    name: "health-check",
    component: HealthCheckView,
    title: "Health Check",
  }
];

const protectedRoutes = [
  {
    path: "/",
    name: "insurance-navigator-page",
    component: InsuranceNavigatorPage,
    title: "Insurance Navigator Page",
  },
  {
    path: "/benefit-investigation",
    name: "benefit-investigation",
    component: BenefitInvestigationPage,
    title: "Benefit Investigation",
  },
  {
    path: "/check-my-coverage/:id?",
    name: "check-my-coverage",
    component: RequirementsPage,
    title: "Insurance Criteria",
  },
  {
    path: "/prior-authorization-form/:id?",
    name: "prior-authorization-form",
    component: UploadPriorAuthFormPage,
    title: "Prior Authorization Form",
  },
  {
    path: "/access-initiated-prior-auth/:id",
    name: "access-initiated-prior-auth",
    component: ReviewInitiatedPriorAuthPage,
    title: "Access Initiated Prior Authorization",
  },
  {
    path: '/all-submissions',
    name: 'all-submissions',
    component: SubmissionsListPage, 
    title: "All Submissions"
  },
  {
    path: '/submission-details/:id',
    name: 'submission-details',
    component: SubmissionDetailsPage, 
    title: "Submission Details"
  },
  {
    path: '/pdf-submission-details/:id',
    name: 'pdf-submission-details',
    component: PdfSubmissionDetailsPage, 
    title: "Submission Details"
  },
  {
    path: "/all-patients",
    name: "all-patients",
    component: PatientsListPage,
    title: "All Patients",
  },
  {
    path: "/intake-review/:id",
    name: "intake-review",
    component: PatientIntakeReview,
    title: "Intake Review",
  },
  {
    path: "/intake/order/:id/qualify",
    name: "qualify-order",
    component: PatientIntakeQualifyPatient,
    title: "Qualify Patient",
  },
  {
    path: "/intake/order/:id/document-review",
    name: "document-review-order",
    component: PatientIntakeClinicalReview,
    title: "Document Review",
  },
  {
    path: "/order/:id/denial-follow-up",
    name: "denial-follow-up-order",
    component: OrderDenialFollowUp,
    title: "Denial Follow Up",
  },
];

for (const r of publicAccessRoutes) {
  r.meta = { title: r.title || 'Do Prior Auth' }
}

for (const r of protectedRoutes) {
  r.meta = { loginRequired: true, title: r.title || 'Do Prior Auth' }
}

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: publicAccessRoutes.concat(protectedRoutes),
});

router.beforeEach((to) => {
  const authManager = useAuthStore();
  document.title = to.meta.title;
  if (!authManager.loggedIn && to.meta.loginRequired) {
    return { name: "login" };
  }
});

trackRouter(router);

export default router;