import axios from "axios";
import { post } from "axios";
import { fetchAndReturnData } from "../utils/queries";

const uploadFileApiURL = process.env.VUE_APP_FILE_UPLOAD_API + "get-pre-signed-url";
const uploadOCRApiURL = process.env.VUE_APP_FILE_UPLOAD_API + "get-kvps-url";
const getKvpsUrlForUploadedFileURL = process.env.VUE_APP_FILE_UPLOAD_API + "get-kvps-url-for-uploaded-file";
const runTextractForUploadedFileURL = process.env.VUE_APP_FILE_UPLOAD_API + "run-textract-for-uploaded-file";

export const uploadService = {
  async uploadFile(file, fileName) {
    try {
      // First step: Get the pre-signed POST URL
      const initialResponse = await axios.post(
        uploadFileApiURL,
        { fileName: fileName },
        { headers: { "Content-Type": "application/json" } }
      );
      const postData = initialResponse.data;
      const postURL = postData.upload.url;
      const postFields = postData.upload.fields;

      // Create form data for the upload
      const formData = new FormData();
      Object.entries(postFields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", new File([file], fileName));

      // Second step: Upload the file to the presigned POST URL
      await axios.post(postURL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return postData.download;
    } catch (err) {}
  },
  async getKeyValuePairsUrl(uploadKey) {
    try {
      const responseData = await axios.post(
        getKvpsUrlForUploadedFileURL,
        { fileName: uploadKey },
        { headers: { "Content-Type": "application/json" } }
      );
      return responseData.data.kvps;
    } catch (err) {}
  },
  async startTextractForUploadedFileURL(uploadKey) {
    try {
      const responseData = await axios.post(
        runTextractForUploadedFileURL,
        { object_key: uploadKey },
        { headers: { "Content-Type": "application/json" } }
      );
      return responseData;
    } catch (err) {}
  },
  async getResultsFromOCR(file, fileName) {
    try {
      // First step: Get the pre-signed POST URL
      const initialResponse = await axios.post(
        uploadOCRApiURL,
        { fileName: fileName },
        { headers: { "Content-Type": "application/json" } }
      );
      const postData = initialResponse.data;

      const postURL = postData.presigned_post.url;
      const postFields = postData.presigned_post.fields;
      const kvpsURL = postData.kvps;

      // Create form data for the upload
      const formData = new FormData();
      Object.entries(postFields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", new File([file], fileName));

      // Second step: Upload the file to the presigned POST URL
      await post(postURL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const urls = [kvpsURL];
      const responseData = await fetchAndReturnData(urls);
      return responseData;
    } catch (err) {}
  },
};
