<template>
  <div class="patient-information-form-wrapper">
    <GreenCirclePreloader v-if="preloader" />
    <PatientInformationFormPreview
      v-if="!preloader"
      :order-info="orderInfo"
      :is-editable="true"
      :form-button-clicked="formButtonClicked"
      :patient-address-book="patientAddressBook"
      :provider-address-book="providerAddressBook"
      :medication-search-box="medicationSearchBox"
      @update-patient-info="updatePatientInfo"
      @update-provider-info="updateProviderInfo"
      @update-order-info-field="updateOrderInfoField"
      @set-patient-info-from-address-book="setPatientInfoFromAddressBook"
      @set-provider-info-from-address-book="setProviderInfoFromAddressBook"
      @add-extra-medication="addExtraMedication"
      @remove-extra-medication="removeExtraMedication"
      @remove-primary-medication="removePrimaryMedication"
      @update-extra-medication="updateExtraMedicationField"
      @change-formulary="changeFormulary" />
    <!-- <div v-if="!preloader" class="form">
      <div class="patient-information-part">
        <h5>Pharmacy Information <span v-if="orderInfo.benefitType === 'Medical benefits'">(Optional)</span></h5>
        <hr class="bold-hr" />
        <p>
          By providing the pharmacy information, you authorize Do Prior Auth to contact the pharmacy of your choice on
          your behalf to report the status of your Prior Authorization as well as follow-up.
        </p>
        <PharmacyLookupComponent
          :is-editable="true"
          :form-button-clicked="formButtonClicked && orderInfo.benefitType === 'Pharmacy benefits'"
          :pharmacy-selection="orderInfo.pharmacyInfo"
          @pharmacy-selected="setPharmacySelection" />
      </div>
    </div> --->
    <div v-if="!preloader" class="form">
      <div class="patient-information-part">
        <h5>Patient Diagnosis Information</h5>
        <hr class="bold-hr" />
        <DiagnosisSelectionPreviewComponent
          :medication="orderInfo.medication"
          :primary-diagnosis="orderInfo.primaryDiagnosis"
          :other-diagnosis="orderInfo.otherDiagnosis"
          :is-editable="true"
          :form-button-clicked="formButtonClicked"
          @update-primary-diagnosis="updatePrimaryDiagnosis"
          @update-other-diagnosis="updateOtherDiagnosis"
          @add-other-diagnosis="addOtherDiagnosis" />
      </div>
    </div>
    <QuestionsSelectionComponent
      v-if="!preloader"
      :title="basicPlusQuestionsTitle"
      :payer-questions="orderInfo.basicPlusQuestions"
      :checklist="orderInfo.basicPlusChecklist"
      :llm-prediction="orderInfo.basicPlusChecklistLlmScores"
      :form-button-clicked="formButtonClicked"
      :submission-method="orderInfo.submissionMethod"
      @update-checklist="updateBasicPlusChecklistSelection" />
    <!-- <StepTherapiesPreviewComponent
      v-if="!preloader"
      :drugs-tried="orderInfo.drugsTried"
      :is-editable="true"
      @update-drugs-tried="updateDrugsTried" /> -->
    <QuestionsSelectionComponent
      v-if="!preloader"
      :title="payerQuestionsTitle"
      :payer-questions="orderInfo.payerQuestions"
      :checklist="orderInfo.checklist"
      :llm-prediction="orderInfo.checklistLlmScores"
      :form-button-clicked="formButtonClicked"
      :submission-method="orderInfo.submissionMethod"
      @update-checklist="updateChecklistSelection" />
    <SupportingDocumentsPreviewComponent
      v-if="!preloader"
      :order-key="orderInfo.orderKey"
      :medical-necessity="orderInfo.medicalNecessity"
      :lab-results="orderInfo.labResults"
      :patient-chart="orderInfo.patientChart"
      :guidelines="orderInfo.guidelines"
      :medical-necessity-template="orderInfo.medicalNecessityTemplate"
      :supplemental-docs="supplementalDocs"
      :is-editable="true"
      @add-uploaded-file="updateSupportingDocs"
      @add-free-form-supplemental-docs="addFreeFormSupplementalDocs"
      @update-guidelines="updateGuidelines" />
    <ExpeditedReviewComponent
      v-if="!preloader"
      :expedited-review-within24h="orderInfo.expeditedReviewWithin24h"
      :expedited-review-statement="orderInfo.expeditedReviewStatement"
      :is-editable="true"
      @update-order-info-field="updateOrderInfoField" />
    <SignaturePadComponent
      v-if="!preloader"
      :signature="orderInfo.signature"
      :form-button-clicked="formButtonClicked"
      @update-signature="updateSignature" />
    <div v-if="!preloader" class="form">
      <div class="buttons-wrapper">
        <button
          class="return-button"
          :class="{ 'button-clicked': draftSavingInProgress }"
          :disabled="draftSavingInProgress"
          @click="savePartialOrderInfo">
          Save Draft
        </button>
        <button class="next-button" :disabled="draftSavingInProgress" @click="submitOrderInfo">Send To Plan</button>
      </div>
    </div>
    <span v-if="errors.length > 0" class="input-error-notification">
      <span v-for="error in errors" :key="error">{{ error }}</span>
      Sorry, something went wrong. Please contact us at
      <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
    </span>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { orderInfoService } from "@/services/orderInfoService";
import { mainServices } from "@/services/mainServices";
import { tryParseApiErrors, clearOrderInfoStorage, hasDuplicate } from "@/utils";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import PatientInformationFormPreview from "@/pages/PatientInformationFormPreview.vue";
import QuestionsSelectionComponent from "@/components/QuestionsSelectionComponent.vue";
import DiagnosisSelectionPreviewComponent from "@/components/DiagnosisSelectionPreviewComponent.vue";
// import StepTherapiesPreviewComponent from "@/components/StepTherapiesPreviewComponent.vue";
import ExpeditedReviewComponent from "@/components/ExpeditedReviewComponent.vue";
import SignaturePadComponent from "@/components/SignaturePadComponent.vue";
import SupportingDocumentsPreviewComponent from "@/components/SupportingDocumentsPreviewComponent.vue";
// import PharmacyLookupComponent from "@/components/PharmacyLookupComponent";

const { orderInfo } = storeToRefs(useOrderInfoStore());

const draftSavingInProgress = ref(false);
const router = useRouter();
const preloader = ref(false);
const errors = ref([]);
const supplementalDocs = ref([]);
const formButtonClicked = ref(false);
const patientAddressBook = ref([]);
const providerAddressBook = ref([]);
const medicationSearchBox = ref([]);
const payerQuestionsTitle = "Insurance Coverage Criteria";
const basicPlusQuestionsTitle = "Additional Questions";

const props = defineProps({
  addressBooks: {
    type: Object,
    default() {
      return null;
    },
  },
});

onMounted(() => {
  orderInfo.value.formStep = "Review";
  if (
    (!orderInfo.value.patientChart && !orderInfo.value.labResults) ||
    (orderInfo.value.patientChart &&
      orderInfo.value.patientChart.length === 0 &&
      orderInfo.value.labResults &&
      orderInfo.value.labResults.length === 0)
  ) {
    supplementalDocs.value.push({ label: null, links: [] });
  }
  supplementalDocs.value.push({ label: null, links: [] });
  getMedicationSearchBox();
  if (props.addressBooks) {
    patientAddressBook.value = props.addressBooks.patientAddressBook;
    providerAddressBook.value = props.addressBooks.providerAddressBook;
  } else {
    getAddressBooks();
  }
});

onUnmounted(() => {
  clearOrderInfoStorage();
});

async function getMedicationSearchBox() {
  preloader.value = true;
  medicationSearchBox.value = await mainServices.getMedicationSearchBox();
  preloader.value = false;
}

async function getAddressBooks() {
  preloader.value = true;
  const addressBooks = await orderInfoService.getAvailableAddressBooks();
  providerAddressBook.value = addressBooks.providerAddressBook;
  patientAddressBook.value = addressBooks.patientAddressBook;
  preloader.value = false;
}

function updateChecklistSelection(newChecklistSelection, labResults, patientChartNotes, isDeselect = false) {
  if (isDeselect) {
    newChecklistSelection.forEach((unselectedItem) => {
      orderInfo.value.checklist = orderInfo.value.checklist.filter((item) => item.id !== unselectedItem.id);
    });
    if (labResults && labResults.length) {
      labResults.forEach((labResult) => {
        orderInfo.value.labResults = orderInfo.value.labResults.filter((item) => item.label !== labResult.label);
      });
    }
    if (patientChartNotes && patientChartNotes.length) {
      patientChartNotes.forEach((note) => {
        orderInfo.value.patientChart = orderInfo.value.patientChart.filter((item) => item.label !== note.label);
      });
    }
  } else {
    let updatedChecklist = [];
    newChecklistSelection.forEach((selectedItem) => {
      updatedChecklist = orderInfo.value.checklist.map((item) => {
        if (item.id === selectedItem.id) {
          return selectedItem;
        }
        return item;
      });
      if (!updatedChecklist.find((item) => item.id === selectedItem.id)) {
        updatedChecklist.push(selectedItem);
      }
    });
    orderInfo.value.checklist = updatedChecklist;
    if (labResults && labResults.length) {
      labResults.forEach((labResult) => {
        if (!hasDuplicate(orderInfo.value.labResults, labResult)) {
          orderInfo.value.labResults.push({ label: labResult.label, links: [] });
        }
      });
    }
    if (patientChartNotes && patientChartNotes.length) {
      patientChartNotes.forEach((note) => {
        if (!hasDuplicate(orderInfo.value.patientChart, note)) {
          orderInfo.value.patientChart.push({ label: note.label, links: [] });
        }
      });
    }
  }
}

function updateBasicPlusChecklistSelection(newChecklistSelection, labResults, patientChartNotes, isDeselect = false) {
  if (isDeselect) {
    newChecklistSelection.forEach((unselectedItem) => {
      orderInfo.value.basicPlusChecklist = orderInfo.value.basicPlusChecklist.filter(
        (item) => item.id !== unselectedItem.id
      );
    });
  } else {
    let updatedChecklist = [];
    newChecklistSelection.forEach((selectedItem) => {
      updatedChecklist = orderInfo.value.basicPlusChecklist.map((item) => {
        if (item.id === selectedItem.id) {
          return selectedItem;
        }
        return item;
      });
      if (!updatedChecklist.find((item) => item.id === selectedItem.id)) {
        updatedChecklist.push(selectedItem);
      }
    });
    orderInfo.value.basicPlusChecklist = updatedChecklist;
  }
}

function updatePatientInfo(field, value) {
  orderInfo.value = { ...orderInfo.value, patientInfo: { ...orderInfo.value.patientInfo, [field]: value } };
}

function updateProviderInfo(field, value) {
  orderInfo.value.providerInfo[field] = value;
}

function setPatientInfoFromAddressBook(patientInfo, id) {
  orderInfo.value.patientInfo = patientInfo;
  orderInfo.value.patientInfo.id = id;
}

function setProviderInfoFromAddressBook(providerInfo, id) {
  orderInfo.value.providerInfo = providerInfo;
  orderInfo.value.providerInfo.id = id;
}

// function setPharmacySelection(pharmacyInfo) {
//   orderInfo.value.pharmacyInfo = pharmacyInfo;
// }

function updateSignature(signature) {
  orderInfo.value.signature = signature;
}

function updatePrimaryDiagnosis({ icd10Code, diagnosis }) {
  orderInfo.value.primaryDiagnosis.icd10Code = icd10Code;
  orderInfo.value.primaryDiagnosis.diagnosis = diagnosis;
}

function updateOtherDiagnosis({ index, icd10Code, diagnosis }) {
  if (!icd10Code || !diagnosis) {
    orderInfo.value.otherDiagnosis.splice(index, 1);
    orderInfo.value.otherDiagnosis.push({
      diagnosis: null,
      icd10Code: null,
    });
  } else {
    const insertIndex = orderInfo.value.otherDiagnosis.findIndex((item) => !item.diagnosis);
    orderInfo.value.otherDiagnosis[insertIndex]["icd10Code"] = icd10Code;
    orderInfo.value.otherDiagnosis[insertIndex]["diagnosis"] = diagnosis;
  }
}

function addOtherDiagnosis() {
  orderInfo.value.otherDiagnosis.push({ icd10Code: null, diagnosis: null });
}

function mergeSupplementalDocsWithPatientChart() {
  if (!Array.isArray(supplementalDocs.value)) {
    supplementalDocs.value = [];
  }

  if (!Array.isArray(orderInfo.value.patientChart)) {
    orderInfo.value.patientChart = [];
  }

  supplementalDocs.value.forEach((doc) => {
    if (doc.links?.length > 0) {
      orderInfo.value.patientChart.push({
        label: doc.label,
        links: [...doc.links],
      });
    }
  });
}

function updateOrderInfoField(field, value) {
  orderInfo.value[field] = value;
}

function addFreeFormSupplementalDocs(downloadLinkInfo) {
  if (!supplementalDocs.value[downloadLinkInfo.storeVarIndex]) {
    supplementalDocs.value[downloadLinkInfo.storeVarIndex] = { label: null, links: [] };
  }
  supplementalDocs.value[downloadLinkInfo.storeVarIndex].links.push(downloadLinkInfo.link);
  supplementalDocs.value[downloadLinkInfo.storeVarIndex].label = downloadLinkInfo.fileName;
}

function updateSupportingDocs(downloadLinkInfo) {
  if (downloadLinkInfo.label) {
    const foundItem = orderInfo.value[downloadLinkInfo.storeVar].find((item) => item.label === downloadLinkInfo.label);
    if (foundItem) {
      foundItem.links.push(downloadLinkInfo.link);
    }
  } else {
    orderInfo.value[downloadLinkInfo.storeVar]
      ? orderInfo.value[downloadLinkInfo.storeVar].push(downloadLinkInfo.link)
      : (orderInfo.value[downloadLinkInfo.storeVar] = [downloadLinkInfo.link]);
  }
}

function updateGuidelines(guidelines) {
  orderInfo.value.guidelines = guidelines;
}

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  if (supplementalDocs.value.length > 0 && supplementalDocs.value[0].links.length > 0) {
    mergeSupplementalDocsWithPatientChart();
  }
  if (!orderInfo.value.orderKey) {
    const newOrder = await orderInfoService.createEmptyOrder(orderInfo.value);
    orderInfo.value.orderKey = newOrder.orderKey;
  }
  await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
  draftSavingInProgress.value = false;
}

async function submitOrderInfo() {
  errors.value = [];
  formButtonClicked.value = true;
  await new Promise((resolve) => setTimeout(resolve, 0));
  const missingInput = document.getElementsByClassName("input-error-notification");
  if (missingInput.length === 0) {
    draftSavingInProgress.value = true;
    try {
      orderInfo.value.formStep = "Send to Plan";
      if (supplementalDocs.value.length > 0 && supplementalDocs.value[0].links.length > 0) {
        mergeSupplementalDocsWithPatientChart();
      }
      if (!orderInfo.value.orderKey) {
        const newOrder = await orderInfoService.createEmptyOrder(orderInfo.value);
        orderInfo.value.orderKey = newOrder.orderKey;
      }
      await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
      errors.value = [];
      clearOrderInfoStorage();
      router.push({ name: "all-submissions" });
      window.scrollTo(0, 0);
    } catch (err) {
      errors.value = tryParseApiErrors(err);
    }
    draftSavingInProgress.value = false;
  } else {
    setTimeout(() => {
      const parentDiv = missingInput[0].parentNode;
      window.scrollTo({
        top: parentDiv.offsetTop,
        behavior: "smooth",
      });
    }, 0);
  }
}

function addExtraMedication() {
  orderInfo.value.extraMedications.push({
    medication: "",
    strength: "",
    expectedLengthOfTherapy: "",
    quantityPer30Days: "",
    drugQuantityQualifier: "",
    dailyDosage: "",
  });
}

function removeExtraMedication(index) {
  orderInfo.value.extraMedications.splice(index, 1);
}

function removePrimaryMedication() {
  if (orderInfo.value.extraMedications.length > 0) {
    let newPrimaryMedication = orderInfo.value.extraMedications.splice(0, 1);
    orderInfo.value.medication = newPrimaryMedication[0].medication;
    orderInfo.value.strength = newPrimaryMedication[0].strength;
    orderInfo.value.expectedLengthOfTherapy = newPrimaryMedication[0].expectedLengthOfTherapy;
    orderInfo.value.quantityPer30Days = newPrimaryMedication[0].quantityPer30Days;
    orderInfo.value.drugQuantityQualifier = newPrimaryMedication[0].drugQuantityQualifier;
    orderInfo.value.routeAdministration = newPrimaryMedication[0].routeAdministration;
  } else {
    orderInfo.value.medication = "";
    orderInfo.value.strength = "";
    orderInfo.value.expectedLengthOfTherapy = "";
    orderInfo.value.quantityPer30Days = "";
    orderInfo.value.dailyDosage = "";
    orderInfo.value.drugQuantityQualifier = "";
    orderInfo.value.routeAdministration = "";
  }
}

function updateExtraMedicationField(index, field, newValue) {
  orderInfo.value.extraMedications[index][field] = newValue;
}

async function changeFormulary() {
  await new Promise((resolve) => setTimeout(resolve, 0));
  preloader.value = true;
  try {
    if (supplementalDocs.value.length > 0 && supplementalDocs.value[0].links.length > 0) {
      mergeSupplementalDocsWithPatientChart();
    }
    await orderInfoService.changeOrderFormulary(orderInfo.value.orderKey, orderInfo.value);
    errors.value = [];
    clearOrderInfoStorage();
    router.push({ name: "all-submissions" });
    window.scrollTo(0, 0);
  } catch (err) {
    errors.value = tryParseApiErrors(err);
  }
  preloader.value = true;
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
