import api from "@/services/api";

const { get, patch, post } = api.getPrefixedMethods("patients");

const formatDob = (dob) => {
  const dobDate = new Date(dob);
  return [
    dobDate.getFullYear(),
    (dobDate.getMonth() + 1).toString().padStart(2, "0"),
    dobDate.getDate().toString().padStart(2, "0"),
  ].join("-");
};

export const patientService = {
  searchByName(value) {
    return get("", { params: { q: value } });
  },
  patchPatient(id, { firstName, lastName, phoneNumber, dob }) {
    return patch(`${id}/`, { firstName, lastName, phoneNumber, dob: formatDob(dob) });
  },
  postPatient({ firstName, lastName, phoneNumber, dob }) {
    return post("", {
      firstName,
      lastName,
      phoneNumber,
      dob: formatDob(dob),
    });
  },
};
