import { ref } from "vue";
import debounce from "lodash.debounce";

/**
 * Creates a reactive remote autocomplete search functionality. This is a reusable composable.
 *
 * @param {Function} apiCallback - A function that performs the API call for the search.
 *                                 It should take a search value as an argument and return a Promise.
 * @returns {Object} An object containing reactive references and a search update function:
 *                   - valueRef: Ref<string> - The current search value.
 *                   - errorRef: Ref<Error|undefined> - Any error that occurred during the search.
 *                   - loadingRef: Ref<boolean> - Indicates whether a search is in progress.
 *                   - itemsRef: Ref<Array> - The search results.
 *                   - searchUpdate: Function - A debounced function to update the search.
 */
export const useRemoteAutocompleteSearch = (apiCallback) => {
  const itemsRef = ref([]);
  const valueRef = ref("");
  const searchRef = ref("");
  const errorRef = ref(undefined);
  const loadingRef = ref(false);

  const isSearchValueSelected = (search) => {
    const searchItem = itemsRef.value.find((v) => v.title === search);
    return searchItem && searchItem.value === valueRef.value;
  };

  const debouncedApi = debounce((value) => {
    const promise = apiCallback(value);
    if (promise) {
      promise
        .then((response) => {
          loadingRef.value = false;
          itemsRef.value = response;
        })
        .catch((err) => {
          loadingRef.value = false;
          itemsRef.value = [];
          errorRef.value = err;
        });
    } else {
      loadingRef.value = false;
    }
  }, 500);
  // The update:search listener
  // Should make the API request and update the "items"
  const searchUpdate = (value) => {
    if (!value) {
      return;
    } else if (isSearchValueSelected(value)) {
      // Is the text value the same as the selected value
      // then we don't want to search again, since this was a selection
      searchRef.value = valueRef.value;
      return;
    }
    loadingRef.value = true;
    searchRef.value = value;
    debouncedApi(value);
  };

  const reset = (initialValue) => {
    valueRef.value = initialValue;
    searchRef.value = initialValue;
    errorRef.value = undefined;
    loadingRef.value = false;
    itemsRef.value = [];
    // Run a search so the dropdown is prepropulated with the initial value
    searchUpdate(initialValue);
  };

  return { valueRef, searchRef, errorRef, loadingRef, itemsRef, searchUpdate, reset };
};
