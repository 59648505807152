<template>
  <PriorHeader />
  <GreenCirclePreloader v-if="showLoader" />
  <v-alert v-if="errorMsg" :text="errorMsg" type="error" variant="outlined"></v-alert>
  <div v-if="!showLoader" class="patient-intake-container">
    <h1 class="main-header">Document Review</h1>
    <div class="review-container">
      <!-- <ReviewHeader :intake-status="intakeStatus" :page="currentPage" @type-change="onDocumentTypeChange" />
      <hr class="bg-blue" /> -->
      <PatientBar v-if="patient" :patient="patient" :disabled="true" @change-attribute="onPatientAttributeChange" />
      <PagePreview
        v-if="currentPage"
        :page="currentPage"
        :display-edit-fields="false"
        :stage="intakeStatus"
        :tags="order?.pageTags[currentPage.id]"
        :total-pages="pageManager._pages.length"
        :current-page-number="pageManager.currentPageIndex + 1"
        @attribute-edit="(field, value) => pageManager.updateCurrentPageAttribute(field.key, value)">
        <div class="review-tags">
          <div class="tags-completed"></div>
          <div class="tags-list">
            <v-container>
              <v-row no-gutters>
                <v-col class="ma-2">
                  <v-btn block class="position-relative" @click="() => goToPage(0)">
                    <span class="position-absolute left-0 ml-4">Insurance Card Page</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="ma-2">
                  <v-btn block class="position-relative" @click="() => goToPage(1)">
                    <span class="position-absolute left-0 ml-4">Order Form Page</span>
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              Tag this document page with:
              <v-row v-for="t in tagsList" :key="t" no-gutters>
                <!-- <button @click="onTagClick">{{ t }}</button> -->
                {{ void (pageTags = order.pageTags[currentPage.id]) }}
                {{ void (isTagged = pageTags && pageTags.indexOf(t) > -1) }}
                <v-col class="ma-2">
                  <v-btn block class="position-relative" @click="() => onTagClick(t)">
                    <span class="position-absolute left-0 ml-4">{{ t }}</span>
                    <v-chip
                      label
                      :color="isTagged ? 'blue' : 'red'"
                      variant="outlined"
                      size="small"
                      class="position-absolute right-0 mr-4">
                      {{ isTagged ? "REMOVE ID" : "ID PAGE" }}
                    </v-chip>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </PagePreview>
      <div class="document-footer">
        <BottomBar
          :current-page="currentPage"
          :finish-review-enabled="finishReviewEnabled"
          :intake-status="intakeStatus"
          :total-pages="totalPages"
          @submit-step="submitStep"
          @back-step="backStep"
          @prev-page="() => pageManager.onPrevPage()"
          @next-page="() => pageManager.onNextPage()" />
        <v-form class="clinical-footer" @submit.prevent>
          <div class="submit-buttons">
            <v-btn class="go-back" @click="onBack">Go Back</v-btn>
            <v-btn class="secondary" @click="onRequestMissingInformation">Request Missing Information</v-btn>
            <v-btn @click="submitStep">No Missing Information, Start Prior Authorization </v-btn>
          </div>
        </v-form>
        <v-container v-if="missingInformationVisible" max-width="900">
          <br />
          <v-row>Message to Provider</v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="missingInformation" label="Missing Information" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="onRequestMissingInformation">Cancel</v-btn>
            </v-col>
            <v-col>
              <v-btn class="float-right" @click="onMissingInformationSubmit">Send Fax</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import PriorHeader from "@/components/PriorHeader.vue";
import PriorFooter from "@/components/PriorFooter.vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import { useDate } from "vuetify";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { intakeService } from "@/services/intakeService";
import PagePreview from "@/components/intakeReview/PagePreview.vue";
import PatientBar from "@/components/intakeReview/PatientBar.vue";
import BottomBar from "@/components/intakeReview/BottomBar.vue";
// import ReviewHeader from "@/components/intakeReview/ReviewHeader.vue";
import { IntakeDocumentPage, Patient, PageChangeManager } from "@/models/intake";

const dateAdapter = useDate();
const showLoader = ref(true);
const intakeStatus = ref("Clinical Review");
const currentPage = ref(undefined);
const pageManager = new PageChangeManager([], currentPage, {});
const route = useRoute();
const orderId = route.params.id;
const order = ref(undefined);
const patient = ref(null);
const tagsList = ref([]);
const router = useRouter();
const errorMsg = ref(undefined);
const missingInformationVisible = ref(false);
const missingInformation = ref("");

const onTagClick = async (tag) => {
  errorMsg.value = "";
  try {
    const pageTags = order.value.pageTags[currentPage.value.id];
    if (!pageTags || pageTags.indexOf(tag) === -1) {
      const result = await intakeService.postIntakeOrderPageTag(orderId, currentPage.value.id, tag);
      order.value = result;
    } else {
      const result = await intakeService.deleteIntakeOrderPageTag(orderId, currentPage.value.id, tag);
      order.value = result;
    }
  } catch (ex) {
    errorMsg.value = "Error tagging the document page";
  }
};

onMounted(() => {
  fetchIntakeOrder();
});

const onBack = () => {
  router.push({ name: "all-patients" });
};

const onRequestMissingInformation = () => {
  missingInformationVisible.value = !missingInformationVisible.value;
};

const onMissingInformationSubmit = async () => {
  try {
    showLoader.value = true;
    await intakeService.postSendMissingInfoFax(order.value.id, missingInformation.value);
    router.push({ name: "all-patients" });
  } catch (e) {
    errorMsg.value = "Failed to send the fax";
  } finally {
    showLoader.value = false;
  }
};

const goToPage = (n) => {
  pageManager.goToPage(n);
};

const submitStep = async () => {
  showLoader.value = true;
  errorMsg.value = "";
  try {
    const orderInfo = await intakeService.postOrdersSubmitClinicalReview(order.value.id);
    router.push(`/check-my-coverage/${orderInfo.orderKey}`);
  } catch (ex) {
    errorMsg.value = `Error submitting the review: ${ex.message}`;
  } finally {
    showLoader.value = false;
  }
};

const fetchIntakeOrder = async () => {
  showLoader.value = true;
  errorMsg.value = "";
  try {
    const orderData = await intakeService.getIntakeOrder(orderId);
    order.value = orderData;
    patient.value = new Patient(orderData.patient, dateAdapter);
    const pageResults = await intakeService.getPatientDocumentPages(orderData.patient.id, "OTHER");
    const pages = [
      new IntakeDocumentPage(orderData.insuranceCardPage),
      new IntakeDocumentPage(orderData.orderFormPage),
    ];
    pageResults.results.forEach((p) => pages.push(new IntakeDocumentPage(p)));
    pageManager.pages = pages;
    tagsList.value = order.value.availableTags;
  } catch (ex) {
    errorMsg.value = "Error fetching draft order";
  } finally {
    showLoader.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
