<template>
  <v-container v-for="(med, ix) in props.modelValue" :key="ix">
    <v-row no-gutters>
      <v-col> Medication {{ ix + 1 }} </v-col>
      <v-col>
        <v-btn color="error" variant="outlined" @click="onDelete(ix)"> Delete </v-btn>
      </v-col>
    </v-row>
    <br />
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Name"
          :model-value="med.name"
          @update:model-value="(v) => onUpdateModel(med, 'name', v)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Dosage"
          :model-value="med.dosage"
          @update:model-value="(v) => onUpdateModel(med, 'dosage', v)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Delivery Type"
          :model-value="med.deliveryType"
          @update:model-value="(v) => onUpdateModel(med, 'deliveryType', v)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Frequency in days"
          :model-value="med.frequencyDays"
          @update:model-value="(v) => onUpdateModel(med, 'frequencyDays', v)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Duration in days"
          :model-value="med.durationDays"
          @update:model-value="(v) => onUpdateModel(med, 'durationDays', v)"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-textarea
          auto-grow
          rows="2"
          label="Notes"
          :model-value="med.notes"
          @update:model-value="(v) => onUpdateModel(med, 'notes', v)"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
  <v-btn @click="onAdd">+ Add Another Medication</v-btn>
</template>

<script setup>
const emit = defineEmits(["keyup"]);
const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
});

const onUpdateModel = (med, key, value) => {
  // Emit a keyup since the pagepreview requires the key
  med[key] = value;
  emit("keyup", props.modelValue);
};

const onAdd = () => {
  const meds = [...props.modelValue, {}];
  emit("keyup", meds);
};

const onDelete = (ix) => {
  const meds = props.modelValue.filter((_, i) => i !== ix);
  emit("keyup", meds);
};
</script>
