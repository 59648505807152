import api from "@/services/api";

const { get, post } = api.getPrefixedMethods("requirements");

export const mainServices = {
  availableSearchOptions() {
    return get("");
  },
  sendMissingPlanDetails(missingPlanDetails) {
    return post(`add-missing-plan/`, missingPlanDetails);
  },
  searchPriorAuthForms(searchTerm) {
    return post(`search-form/`, searchTerm);
  },
  getPriorAuthForm(id, medicationDetails) {
    return post(`form/${id}`, medicationDetails);
  },
  getInsuranceProviders() {
    return get(`insurance-providers/`);
  },
  fetchDiagnosisRequirements(medication) {
    return post(`diagnosis-requirements/`, medication);
  },
  searchPharmacies(searchTerm) {
    return post("search-pharmacies/", searchTerm);
  },
  getNearestPharmacies(zipCode) {
    return post("get-pharmacies-nearby/", zipCode);
  },
  getMedicationSearchBox() {
    return get("get-medications/");
  },
};
