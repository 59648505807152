<template>
  <div class="f-700">
    <div class="form-subtitle f-700">Identify Patient</div>
    <v-form v-if="patient === undefined && !addPatientState" @submit.prevent>
      <div>Verify Patient with Name, and Date of Birth or Phone</div>
      <v-autocomplete
        v-model="autocomplete.valueRef"
        :items="autocomplete.itemsRef"
        :search="autocomplete.searchRef"
        @update:search="autocomplete.searchUpdate"
        @update:model-value="onSelectPatient">
        <template #append-item>
          <div @click="setAddPatientState">&nbsp;+ Add a new patient record</div>
        </template>
      </v-autocomplete>
    </v-form>
    <v-form v-if="patient !== undefined || addPatientState" action="">
      <v-container class="ml-2 mr-2">
        <v-row>
          <span class="patient-info-input">
            <div>Patient First Name</div>
            <v-text-field
              type="text"
              :model-value="patient?.firstName"
              :disabled="props.disabled"
              width="200px"
              @update:model-value="(v) => onChangeInfo('firstName', v)"></v-text-field>
          </span>
          <span class="patient-info-input">
            <div>Patient Last Name</div>
            <v-text-field
              type="text"
              :model-value="patient.lastName"
              :disabled="props.disabled"
              width="200px"
              @update:model-value="(v) => onChangeInfo('lastName', v)"></v-text-field>
          </span>
          <span class="patient-info-input">
            <div>Date of Birth</div>
            <v-menu :disabled="props.disabled">
              <template #activator="acProps">
                <v-btn width="200px" height="62px" :disabled="props.disabled" v-bind="acProps.props">{{
                  patient.dob?.toLocaleDateString()
                }}</v-btn>
              </template>
              <template #default>
                <span @click.stop>
                  <v-date-picker
                    :model-value="patient.dob"
                    @update:model-value="(value) => onChangeInfo('dob', value)"></v-date-picker>
                </span>
              </template>
            </v-menu>
          </span>
          <span class="patient-info-input">
            <div>Phone</div>
            <v-text-field
              type="tel"
              :model-value="patient.phoneNumber"
              :disabled="props.disabled"
              width="200px"
              @update:model-value="(v) => onChangeInfo('phoneNumber', v)"></v-text-field>
          </span>
          <span v-if="!(props.disabled || addPatientState)" class="patient-info-input">
            <div>Not this patient?</div>
            <v-btn class="mt-3" @click="changePatient">Change</v-btn>
          </span>
        </v-row>
        <v-row v-if="addPatientState">
          <v-btn @click="saveNewPatient">Save new patient</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script setup>
import { Patient } from "@/models/intake";
import { useRemoteAutocompleteSearch } from "@/utils/autocomplete";
import { onMounted, ref, watch } from "vue";
import { patientService } from "@/services/patientService";
import { useDate } from "vuetify";

const dateAdapter = useDate();
const emit = defineEmits(["change-attribute", "set-patient", "save-new-patient"]);
const props = defineProps({
  patient: {
    required: true,
    type: Patient,
  },
  patientGuess: {
    required: false,
    type: Object,
    default: undefined,
  },
  disabled: {
    default: false,
    type: Boolean,
    default: false,
  },
});
const addPatientState = ref(false);
const patient = ref(props.patient);

watch(addPatientState, (newState) => {
  if (newState) {
    patient.value = new Patient({ dob: "1980-01-01", ...(props.patientGuess ?? {}) }, dateAdapter);
  } else {
    patient.value = props.patient;
  }
});

const setAddPatientState = () => {
  addPatientState.value = !addPatientState.value;
};

const fetchPatientAutocomplete = (value) => {
  if (value.length > 25) return;
  return patientService.searchByName(value).then((response) =>
    response.results.map((patient) => {
      return {
        title: `${patient.firstName} ${patient.lastName} ${patient.dob ?? "DOB: N/A"} ${
          patient.phoneNumber ?? "PHONE: N/A"
        }`,
        value: patient.id,
        patient,
      };
    })
  );
};

const autocomplete = ref(useRemoteAutocompleteSearch(fetchPatientAutocomplete));

onMounted(() => {
  if (props.patientGuess) {
    const searchValue = [props.patientGuess.firstName || "", props.patientGuess.lastName || ""].join(" ");
    autocomplete.value.searchUpdate(searchValue);
  }
});

const onSelectPatient = (value) => {
  if (!value) return;
  const item = autocomplete.value.itemsRef.find((item) => item.value === value);
  if (item !== undefined) {
    emit("set-patient", item.patient);
    patient.value = new Patient(item.patient, dateAdapter);
  }
};

const onChangeInfo = (name, value) => {
  if (!addPatientState.value) {
    emit("change-attribute", name, value);
  } else {
    patient.value[name] = value;
  }
};

const saveNewPatient = () => {
  emit("save-new-patient", { ...patient.value });
};

const changePatient = () => {
  patient.value = undefined;
};
</script>

<!-- TODO: split css files -->
<style lang="scss" scoped>
@import "@/styles/pages/_patient-intake-review.scss";
</style>
