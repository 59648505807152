export class IntakeDocumentPage {
  constructor(page) {
    this.id = page.id;
    this.documentKey = page.documentKey;
    this.imagePresignedUrl = page.imagePresignedUrl;
    this.documentType = page.documentType;
    this.pageNumber = page.pageNumber;
    this.patientFirstName = page.patientFirstName;
    this.patientLastName = page.patientLastName;
    this.patientDob = page.patientDob;
    this.patientPhone = page.patientPhone;
    this.patientWeight = page.patientWeight;
    this.patientStatus = page.patientStatus;
    this.patientAllergies = page.patientAllergies;
    this.diagnosisIcd10Code = page.diagnosisIcd10Code;
    this.providerFullName = page.providerFullName;
    this.providerNpi = page.providerNpi;
    this.providerFullAddress = page.providerFullAddress;
    this.providerPracticeName = page.providerPracticeName;
    this.providerPhone = page.providerPhone;
    this.providerFax = page.providerFax;
    this.preMedication = page.preMedication;
    this.therapyAdministration = page.therapyAdministration;
    this.otherCheckboxes = page.otherCheckboxes;
    this.specialInstructions = page.specialInstructions;
    this.insurancePlan = page.insurancePlan;
    this.phone1Number = page.phone1Number;
    this.phone1Description = page.phone1Description;
    this.phone2Number = page.phone2Number;
    this.phone2Description = page.phone2Description;
    this.fax1Number = page.fax1Number;
    this.fax1Description = page.fax1Description;
    this.memberName = page.memberName;
    this.memberId = page.memberId;
    this.memberDob = page.memberDob;
    this.payorName = page.payorName;
    this.planType = page.planType;
    this.state = page.state;
    this.groupNumber = page.groupNumber;
    this.startDate = page.startDate;
    this.endDate = page.endDate;
    this.rxBin = page.rxBin;
    this.rxPcn = page.rxPcn;
    this.rxGrp = page.rxGrp;
  }
}

export class Patient {
  constructor(patient, dateAdapter) {
    this.createdOn = patient.createdOn;
    this.modifiedOn = patient.modifiedOn;
    this.firstName = patient.firstName;
    this.lastName = patient.lastName;
    this.phoneNumber = patient.phoneNumber;
    this.dob = patient.dob ? dateAdapter.parseISO(patient.dob) : null;
    this.dobStr = patient.dob;
    this.id = patient.id;
  }
}

export const DOCUMENT_CLASSIFICATIONS = {
  INSURANCE_CARD: "INSURANCE_CARD",
  ORDER_FORM: "ORDER_FORM",
  OTHER: "OTHER",
  PAYOR_RESPONSE: "PAYOR_RESPONSE",
  APPROVAL: "APPROVAL",
  DENIAL: "DENIAL",
};

export const DOCUMENT_CLASSIFICATION_LABELS = {
  [DOCUMENT_CLASSIFICATIONS.INSURANCE_CARD]: "Insurance Data",
  [DOCUMENT_CLASSIFICATIONS.ORDER_FORM]: "Order Data",
  [DOCUMENT_CLASSIFICATIONS.OTHER]: "Patient Data",
  [DOCUMENT_CLASSIFICATIONS.PAYOR_RESPONSE]: "Payor Response",
  [DOCUMENT_CLASSIFICATIONS.APPROVAL]: "Approval",
  [DOCUMENT_CLASSIFICATIONS.DENIAL]: "Denial",
};

export class PageChangeManager {
  constructor(pages, currentPageRef, { autoSaveCallback }) {
    this.currentPageIndex = 0;
    this._pages = pages;
    this.currentPageRef = currentPageRef;
    this.autoSaveCallback = autoSaveCallback;
    this.currentPageRef.value = this._pages[this.currentPageIndex];
    this.dirtyValues = {};
  }

  get currentPage() {
    return this.currentPageRef.value;
  }

  set pages(pages) {
    this._pages = pages;
    this.currentPageIndex = 0;
    this.currentPageRef.value = this._pages[this.currentPageIndex];
  }

  goToPage(num) {
    if (num >= 0 && num < this._pages.length) {
      this.currentPageIndex = num;
      this.currentPageRef.value = this._pages[this.currentPageIndex];
    }
  }

  async onNextPage() {
    if (this.currentPageIndex < this._pages.length - 1) {
      if (!(await this.saveCurrentPage())) {
        return;
      }
      this.currentPageIndex++;
      this.currentPageRef.value = this._pages[this.currentPageIndex];
    }
  }

  async onPrevPage() {
    if (this.currentPageIndex > 0) {
      if (!(await this.saveCurrentPage())) {
        return;
      }
      this.currentPageIndex--;
      this.currentPageRef.value = this._pages[this.currentPageIndex];
    }
  }

  updateCurrentPageAttribute(name, value) {
    if (value === undefined) return;
    this.dirtyValues[name] = value;
    this.currentPageRef.value[name] = value;
  }

  async saveCurrentPage() {
    if (this.autoSaveCallback && Object.keys(this.dirtyValues).length) {
      const success = await this.autoSaveCallback(this.currentPageRef, this.dirtyValues);
      if (success) {
        this.dirtyValues = {};
      }
      return success;
    }
    return true;
  }
}
