import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import "./styles/main.scss";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";

const vuetify = createVuetify({
  components,
  directives,
  defaults: {
    VBtn: {
      ripple: false,
      class: ["text-none"],
      elevation: 1,
      // height: "62px",
    },
    VTextField: {
      variant: "outlined",
      bgColor: "white",
    },
    VTextarea: {
      variant: "outlined",
      bgColor: "white",
    },
    VAutocomplete: {
      variant: "outlined",
      bgColor: "white",
    },
    VSelect: {
      variant: "outlined",
      bgColor: "white",
    },
    VCombobox: {
      variant: "outlined",
      bgColor: "white",
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App).use(router).use(vuetify).use(createHead()).use(createPinia()).mount("#app");
