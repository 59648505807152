<template>
  <PriorHeader />
  <GreenCirclePreloader v-if="showLoader" />
  <v-alert v-if="errorMsg" :text="errorMsg" type="error" variant="outlined"></v-alert>
  <v-container v-if="!showLoader">
    <v-row>
      <v-col cols="12">
        <h1>Order Denial Follow Up</h1>
      </v-col>
    </v-row>
    <v-row>
      <div v-if="!showLoader && order" class="submission-status-wrapper">
        <div :key="order.orderKey">
          <div class="card">
            <div class="card-container">
              <div>
                <div class="card-header">
                  <span style="grid-column: 1"><strong>Patient Name:</strong> {{ getPatientName(order) }} </span>
                  <span style="grid-column: 2"><strong>Order Status:</strong> {{ order.status }}</span>
                </div>
                <div class="card-content">
                  <div class="card-row request-text">
                    <span><strong>Date Created:</strong> {{ convertDateFormat(order.createdOn) }}</span>
                    <span><strong>Insurance:</strong> {{ titleCaseString(order.insuranceProvider) }}</span>
                    <span><strong>Key:</strong> {{ order.orderKey }}</span>
                  </div>
                  <div class="card-row request-text">
                    <span><strong>Date Modified:</strong> {{ convertDateFormat(order.modifiedOn) }}</span>
                    <span><strong>Plan Type:</strong> {{ order.insurancePlanType }}</span>
                    <span
                      ><strong>Medication:</strong> {{ titleCaseString(order.medication)
                      }}<span v-if="order.extraMedications?.length"
                        >{{ ", " + order.extraMedications.map((med) => titleCaseString(med["medication"])).join(", ") }}
                      </span>
                    </span>
                  </div>
                  <div class="card-row request-text">
                    <span><strong>Type:</strong> {{ titleCaseString(order.benefitType) }}</span>
                    <span>
                      <strong>Prior Auth Number:</strong>
                      {{ order.orderSubmission ? order.orderSubmission.priorAuthNumber : "" }}
                    </span>
                    <span>
                      <strong>Expires on:</strong>
                      {{
                        !order.orderSubmission
                          ? ""
                          : order.orderSubmission.priorAuthExpiration
                          ? convertDateFormat(order.orderSubmission.priorAuthExpiration)
                          : ""
                      }}
                    </span>
                  </div>
                  <div class="card-row request-text">
                    <span class="status-notes">
                      <strong>Status Notes:</strong>
                      {{ getOrderSubmissionStatus(order) }}
                    </span>
                    <span v-if="expiresSoon(order)"><strong class="red-text">Expires soon!</strong></span>
                    <hr v-else class="light-hr" />
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <v-btn class="" @click="selectSubmission(order)">View Details</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <v-row><br /></v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Appeal Tracking</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="newNote" label="Notes" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn @click="addNote">Add Note</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="note in denialNotes" :key="note.id">
              <v-col cols="12">
                {{ note.userName }} [{{ new Date(note.createdOn).toLocaleString() }}]
                <br />
                {{ note.note }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <PriorFooter />
</template>

<script setup>
import PriorHeader from "@/components/PriorHeader.vue";
import PriorFooter from "@/components/PriorFooter.vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import { orderInfoService } from "@/services/orderInfoService";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";

const showLoader = ref(true);
const errorMsg = ref("");
const order = ref(null);
const newNote = ref("");
const denialNotes = ref([]);

const route = useRoute();
const router = useRouter();
const orderId = route.params.id;

onMounted(async () => {
  try {
    const response = await orderInfoService.getOrderDetails(orderId);
    order.value = response.orderInfo;
    const denialNotesResponse = await orderInfoService.getOrderDenialNotes(orderId);
    denialNotes.value = denialNotesResponse.results;
  } catch (e) {
    errorMsg.value = "Failed to fetch the order";
  } finally {
    showLoader.value = false;
  }
});

const addNote = async () => {
  try {
    errorMsg.value = "";
    showLoader.value = true;
    await orderInfoService.addOrderDenialNote(orderId, newNote.value);
    newNote.value = "";
    const denialNotesResponse = await orderInfoService.getOrderDenialNotes(orderId);
    denialNotes.value = denialNotesResponse.results;
  } catch (e) {
    errorMsg.value = "Failed to add note";
  } finally {
    showLoader.value = false;
  }
};

const getPatientName = (order) => {
  return `${order.patientInfo.firstName} ${order.patientInfo.lastName}`;
};

const convertDateFormat = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const titleCaseString = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

const getOrderSubmissionStatus = (order) => {
  return order.orderSubmission ? order.orderSubmission.status : "No submission found";
};

const expiresSoon = (order) => {
  return order.orderSubmission ? order.orderSubmission.expiresSoon : false;
};

const selectSubmission = (order) => {
  router.push(`/submission-details/${order.orderKey}`);
};
</script>

<style scoped>
@import "../styles/pages/_submission-status.scss";

.submission-status-wrapper {
  min-height: unset;
  margin: 0;
  width: 100%;
}
</style>
