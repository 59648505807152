import { ref } from "vue";
import executor from "@/services/api";
import { useRemoteAutocompleteSearch } from "../autocomplete";
import MedicationInput from "@/components/intakeReview/MedicationInput.vue";

const formatNotes = (value) => value && JSON.stringify(value);

const onChangeIcd10Code = (value) => {
  if (value.length > 8) return;

  const icd10SearchPromise = executor.get(`/requirements/search-icd10/?q=${value}`);
  // Axios 0.28 has an issue with AbortController, when upgrading to 1.X this can be updated
  return icd10SearchPromise.then((response) =>
    response.results.map((c) => ({ title: `${c.code} - ${c.name}`, value: c.code }))
  );
};

const icd10VA = useRemoteAutocompleteSearch(onChangeIcd10Code, { invalidValueMessage: "Invalid ICD-10 code" });

export const resetFields = ({ diagnosisIcd10Code }) => {
  icd10VA.reset(diagnosisIcd10Code);
};

const requiredRule = (v) => {
  return !v || v?.length === 0 ? "This field is required" : true;
};

const icd10CodeRule = (v) => {
  return icd10VA.itemsRef.value.some((item) => item.value === v) ? true : "Invalid ICD 10 Code";
};

export const orderFormFields = [
  { label: "Weight", rules: [requiredRule], key: "patientWeight" },
  { label: "Allergies", rules: [], key: "patientAllergies" },
  { label: "Patient Status", rules: [requiredRule], key: "patientStatus" },
  {
    label: "Diagnosis ICD-10 Code",
    cmp: {
      component: "v-autocomplete",
      value: icd10VA.valueRef,
      vBind: {
        rules: [requiredRule, icd10CodeRule],
        items: icd10VA.itemsRef,
        search: icd10VA.searchRef,
        loading: icd10VA.loadingRef,
        menuProps: { maxWidth: "100%" },
      },
      vOn: {
        "update:search": icd10VA.searchUpdate,
      },
    },
    key: "diagnosisIcd10Code",
  },
  { label: "Provider Name", rules: [requiredRule], key: "providerFullName" },
  { label: "Provider NPI", rules: [requiredRule], key: "providerNpi" },
  { label: "Provider Fax", rules: [requiredRule], key: "providerFax" },
  { label: "Provider Phone", key: "providerPhone" },
  { label: "Practice Name", key: "providerPracticeName" },
  { label: "Practice Address", key: "providerFullAddress" },
  {
    label: "Medication",
    key: "therapyAdministration",
    component: MedicationInput,
  },
  {
    label: "Pre-Medication",
    key: "preMedication",
    component: MedicationInput,
  },
  { label: "Notes", key: "otherCheckboxes", component: "TextArea", format: formatNotes },
];

export const insuranceCardFields = [
  { label: "Member", rules: [requiredRule], key: "memberName" },
  { label: "Payor", value: "United Healthcare", rules: [requiredRule], key: "payorName" },
  { label: "State", rules: [requiredRule], key: "state" },
  { label: "Group Number", key: "groupNumber" },
  { label: "Member ID", key: "memberId" },
  {
    label: "Plan",
    key: "insurancePlan",
  },
  { label: "RXBIN", rules: [], key: "rxBin" },
  { label: "RXGROUP", key: "rxGrp" },
  { label: "RXPCN", key: "rxPcn" },
];

export const pageFieldsByType = {
  INSURANCE_CARD: insuranceCardFields,
  ORDER_FORM: orderFormFields,
};

/**
 * Checks if all required fields are present and have non-empty values.
 *
 * @param {Object} values - An object containing field values, where keys correspond to field keys.
 * @param {Array} fields - An array of field objects, oneof insuranceCardFields or orderFormFields.
 * @returns {boolean} Returns true if all required fields have non-empty values, false otherwise.
 */
export const requiredFieldsPresent = (values, fields) => {
  return fields.every((field) => !fields.required || (values[field.key] !== undefined && values[field.key] !== ""));
};

/**
 * Validates all fields in a form based on their requirements and custom validators.
 *
 * @param {Object} values - An object containing the current values of all fields, where keys correspond to field keys.
 * @param {Array} fields - An array of field objects, oneof insuranceCardFields or orderFormFields.
 * @returns {Array} Boolean of whether errors are present, An object containing validation errors. Keys are field keys, and values are objects with 'required' or 'error' properties.
 */
export const validateAllFields = (values, fields) => {
  if (!fields) {
    return [false, {}];
  }
  const errors = {};
  for (const field of fields) {
    const rules = field.rules ?? field.cmp?.vBind?.rules;
    if (rules) {
      for (const rule of rules) {
        const validation = rule(values[field.key]);
        if (validation !== true) {
          errors[field.key] = { error: validation, field };
        }
      }
    }
  }
  return [Object.keys(errors).length > 0, errors];
};

export const useFieldPageErrors = () => {
  const pageErrors = ref({});
  const validateFields = (page) => {
    const [hasErrors, errors] = validateAllFields(page, pageFieldsByType[page.documentType]);
    pageErrors.value = errors;
    return !hasErrors;
  };

  const nextPageWithErrors = (allPages) => {
    for (const pix in allPages) {
      const page = allPages[pix];
      if (!validateFields(page)) {
        return [pix, page];
      }
    }
    return [null, null];
  };

  return { validateFields, nextPageWithErrors, pageErrors };
};
