<template>
  <PriorHeader />

  <div class="all-patients-container">
    <GreenCirclePreloader v-if="preloader" />
    <v-alert v-if="errorMsg" :text="errorMsg" type="error" variant="outlined"></v-alert>
    <v-btn
      height="5px"
      width="5px"
      position="absolute top-0 right-0"
      @click="() => (demoFileInput = !demoFileInput)"></v-btn>
    <UploadFileComponent v-if="demoFileInput" :disabled="demoInputDisabled" @download-link="demoUpload" />
    <div v-if="Object.keys(documentsByLane).length > 0" class="all-patients-list-container">
      <h1 class="main-header">All Patients</h1>
      <div class="all-patients-list">
        <div class="patients-kanban">
          <KanbanLanes
            id="lane1"
            :key="kanbanKey"
            :lane-titles="laneTitles"
            :lane-cards="documentsByLane"
            :next-pages="nextPages"
            @next-page="fetchNextPage" />
        </div>
      </div>
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted } from "vue";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import KanbanLanes from "@/components/kanban/KanbanLanes";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import UploadFileComponent from "@/components/UploadFileComponent";
import { intakeService } from "@/services/intakeService";
import router from "@/router";
import { orderInfoService } from "@/services/orderInfoService";
import executor from "@/services/api";

const demoFileInput = ref(false);
const demoInputDisabled = ref(false);

const demoUpload = async (value) => {
  if (!value) return;
  demoInputDisabled.value = true;
  await intakeService.postDemoUpload(value);
  demoInputDisabled.value = false;
};

const preloader = ref(true);
const kanbanKey = ref(0);
const documentsByLane = ref({});
const nextPages = ref({});
const errorMsg = ref("");
const laneTitles = [
  "Intake",
  "Qualify Patient",
  "Document Review",
  "Prior Authorization",
  "Denial Follow Up",
  "Approved",
];

const statusToTitle = {
  INTAKE: "Intake",
  QUALIFY_PATIENT: "Qualify Patient",
  CLINICAL_REVIEW: "Document Review",
};

const SubmissionStatusToTitle = {
  Created: "Prior Authorization",
  Denied: "Denial Follow Up",
  Approved: "Approved",
};

onMounted(() => {
  fetchAndProcessLanes();
});

const fetchAndProcessLanes = async () => {
  const results = await Promise.allSettled([
    getAllIntakeDocuments(),
    fetchOrders("QUALIFY_PATIENT"),
    fetchOrders("CLINICAL_REVIEW"),
    fetchSubmissions("Created"),
    fetchSubmissions("Denied"),
    fetchSubmissions("Approved"),
  ]);

  results[0].status === "fulfilled" && processIntakeDocuments(results[0].value);
  results[1].status === "fulfilled" && processOrders(results[1].value, "QUALIFY_PATIENT");
  results[2].status === "fulfilled" && processOrders(results[2].value, "CLINICAL_REVIEW");
  results[3].status === "fulfilled" && processSubmissions(results[3].value, "Created");
  results[4].status === "fulfilled" && processSubmissions(results[4].value, "Denied");
  results[5].status === "fulfilled" && processSubmissions(results[5].value, "Approved");
};

const fetchNextPage = async (title) => {
  const response = await executor.get(nextPages.value[title][0]);
  nextPages.value[title][1](response);
};

const getAllIntakeDocuments = async () => {
  // Fetching data from API
  try {
    const response = await intakeService.getIntakeDocuments({ status: "INTAKE" });
    return response;
  } catch (ex) {
    errorMsg.value = "Failed to fetch documents. Please try again later.";
  } finally {
    preloader.value = false;
  }
  kanbanKey.value++;
};

const processIntakeDocuments = (response) => {
  const nowDate = new Date();
  const mappedResponse = response.results.map((r) => {
    const statusChangedAt = new Date(r.statusChangedAt);
    const inStageMinutes = ((nowDate - statusChangedAt) / 60000).toFixed(0);
    const processed = r.ocrTask.status === "OCR_PROCESSED";
    const patientDetails = r.patient ?? r.patientGuess;
    return {
      title: `${patientDetails?.firstName ?? ""} ${patientDetails?.lastName ?? ""}`.trim() || "Unknown Patient",
      disabled: !processed,
      subtitle: processed ? "Ready for Review" : "In Process",
      desc: `${inStageMinutes}m in stage`,
      status: r.status,
      documentKey: r.key,
      onClick: () => {
        router.push(`intake-review/${r.key}`);
      },
    };
  });

  // Append to the existing list
  const current = documentsByLane.value["Intake"] ?? [];
  documentsByLane.value["Intake"] = [...current, ...mappedResponse];

  // Set the next page
  if (response.next) {
    nextPages.value["Intake"] = [response.next, processIntakeDocuments];
  } else {
    nextPages.value["Intake"] = null;
  }
};

const getOrderPageUrl = (order) => {
  if (order.status === "CLINICAL_REVIEW") {
    return `/intake/order/${order.id}/document-review`;
  } else if (order.status === "QUALIFY_PATIENT") {
    return `/intake/order/${order.id}/qualify`;
  } else if (order.status === "PRIOR_AUTHORIZATION") {
    return `check-my-coverage/${order.orderInfo}`;
  }
  return "";
};

const fetchOrders = async (status) => {
  try {
    // Fetching data from API
    const response = await intakeService.getOrders({ status });
    return response;
  } catch (e) {
    errorMsg.value = "There was an error fetching the orders";
  }
  kanbanKey.value++;
};

const processOrders = (response, status) => {
  const nowDate = new Date();
  const mappedResponse = response.results.map((r) => {
    const statusChangedAt = new Date(r.modifiedOn);
    const inStageMinutes = ((nowDate - statusChangedAt) / 60000).toFixed(0);
    let processed = true;
    let statusStr = null;
    if (r.status === "CLINICAL_REVIEW") {
      processed = r.availableTags.length > 0;
    } else if (r.status === "QUALIFY_PATIENT") {
      processed = !!r.patient?.hasInsuranceCard;
      statusStr = "Missing Insurance Data";
    }
    return {
      title: `${r.patient?.firstName} ${r.patient?.lastName}`,
      disabled: !processed,
      subtitle: processed ? "Ready for Review" : statusStr ?? "In Process",
      desc: `${inStageMinutes}m in stage`,
      status: r.status,
      documentKey: r.id,
      onClick: () => {
        router.push(getOrderPageUrl(r));
      },
    };
  });

  // Append to the existing list
  const current = documentsByLane.value[statusToTitle[status]] ?? [];
  documentsByLane.value[statusToTitle[status]] = [...current, ...mappedResponse];

  // Set the next page
  if (response.next) {
    nextPages.value[statusToTitle[status]] = [response.next, (response) => processOrders(response, status)];
  } else {
    nextPages.value[statusToTitle[status]] = null;
  }
};

const fetchSubmissions = async (status) => {
  try {
    const response = await orderInfoService.getListOfOrders(1, status);
    kanbanKey.value++;
    return response;
  } catch (e) {
    errorMsg.value = "There was an error fetching the submissions";
    throw e;
  }
};

const processSubmissions = (response, status) => {
  const routeMapping = {
    Created: "check-my-coverage",
    Denied: "denial-follow-up-order",
    Approved: "submission-details",
  };
  const mappedResponse = response.results.map((r) => {
    return {
      title: r.patientInfo?.firstName + " " + r.patientInfo?.lastName,
      subtitle: "Ready for Review",
      desc: `${r.insurancePlanType} ${r.insuranceProvider}`,
      onClick: () => {
        router.push({ name: routeMapping[status], params: { id: r.orderKey } });
      },
    };
  });

  // Append to the existing list
  const current = documentsByLane.value[SubmissionStatusToTitle[status]] ?? [];
  documentsByLane.value[SubmissionStatusToTitle[status]] = [...current, ...mappedResponse];

  // Set the next page
  if (response.next) {
    nextPages.value[SubmissionStatusToTitle[status]] = [
      response.next,
      (response) => processSubmissions(response, status),
    ];
  } else {
    nextPages.value[SubmissionStatusToTitle[status]] = null;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_submission-status.scss";
@import "@/styles/pages/_patients-list.scss";
</style>
