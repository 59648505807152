import api from "@/services/api";

const { post, get, patch } = api.getPrefixedMethods("intake");

export const intakeService = {
  getIntakeDocuments({ status }) {
    return get("documents", { params: { status } });
  },
  getIntakeDocumentPages(documentKey) {
    return get(`documents/${documentKey}/pages`);
  },
  getIntakeDocument(documentKey) {
    return get(`documents/${documentKey}`);
  },
  patchIntakeDocument(documentKey, values) {
    return patch(`documents/${documentKey}`, values);
  },
  patchIntakeDocumentPage(documentKey, pageId, values) {
    return patch(`documents/${documentKey}/pages/${pageId}`, values);
  },
  postIntakeDocumentSubmit(documentKey, values) {
    return post(`documents/${documentKey}/submit-intake`, values);
  },
  getPatientDocumentPages(patientId, documentType) {
    return get(`patients/${patientId}/pages/`, { params: { document_type: documentType, size: 100 } });
  },
  getOrders({ status }) {
    return get("orders/", { params: { status } });
  },
  getIntakeOrder(orderId) {
    return get(`orders/${orderId}`);
  },
  postOrderSubmitQualify(orderId) {
    return post(`orders/${orderId}/submit-qualify`);
  },
  patchIntakeOrder(orderId, data) {
    return patch(`orders/${orderId}`, data);
  },
  postIntakeOrderPageTag(orderId, pageId, tag) {
    return post(`orders/${orderId}/tag-page`, { page_id: pageId, tag });
  },
  deleteIntakeOrderPageTag(orderId, pageId, tag) {
    return post(`orders/${orderId}/tag-page`, { delete: true, page_id: pageId, tag });
  },
  postOrdersSubmitClinicalReview(orderId) {
    return post(`orders/${orderId}/submit-clinical-review`);
  },
  postDemoUpload({ link }) {
    return post("demo-upload/", { link });
  },
  postSendMissingInfoFax(orderId, text) {
    return post(`orders/${orderId}/send-missing-info-fax/`, { text });
  },
  postDiscontinueProcessing(orderId, notifyProvider, text) {
    return post(`orders/${orderId}/discontinue-processing/`, { notifyProvider, text });
  },
};
